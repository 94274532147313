import React, { useState } from "react";
import revampData from '../../data/revampData.json';

function CurrencyInfiniteScroll({}) {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = (hoverState) => {
    setIsHovered(hoverState);
  };

  const  currencies = revampData.data.listStripeSupportedOptions.data[0].currenciesSupported;



  return (
    <>
      <div className=" pb-4">
        <div
          className="Wrapper "
          onMouseEnter={() => handleHover(true)}
          onMouseLeave={() => handleHover(false)}
        >
          <div className="MarqueeCurrency px-4">
            <ul className="MarqueeCurrencyGroup d-flex flex-row">
              {Object.keys(currencies).map((key, index) => {
                const country = currencies[key];
                if (country) { // Check if country is not null
                  return (
                    <li
                      className="px-3 card-padding d-flex flex-column text-center"
                      key={index}
                    >
                      <div className="d-flex align-items-center justify-content-center currency-symbols custom-border">
                        <p className="currency-symbol-text">
                          {country.currencySymbol}
                        </p>
                      </div>
                      <p className="mt-2 text-uppercase currency-name">
                        {country.currencyCode}
                      </p>
                    </li>
                  );
                }
                return null; // Skip if currency data is null
              })}
            </ul>
          </div>
        </div>
      </div>

      <style>
        {`
          .currency-name {
            font-family: "Nunito";
            font-weight: 400;	 
            font-size: 12px;
            line-height: 14px;
          }
          .currency-symbol-text {
            font-family: "Nunito";
            font-weight: 600;	 
            font-size: 20px;
            line-height: 24px;
            color: #141B25;
          }
          .Wrapper {
            width: 100%;
            height: fit-content;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border-color: #61C0FF;
          }
          .MarqueeCurrency {
            display: flex;
            width: 100%;
            overflow: hidden;
            user-select: none;
          }
          .custom-border {
            border-color: #61C0FF !important;
          }
          @keyframes scrollX {
            from {
              transform: translateX(0);
            }
            to {
              transform: translateX(-100%);
            }
          }
          .MarqueeCurrencyGroup {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            white-space: nowrap;
            animation: scrollX 30s linear infinite;
            ${isHovered ? "animation-play-state: paused;" : ""}
          }
          .currency-symbols {
            height: 50px;
            width: 70px;
            border-radius: 4px;
            border: 1px solid #E7EAEC;
            background-color: #FFFFFF;
          }
          @media only screen and (max-width: 480px) {
            .currency-symbols {
              width: 60px;
            }
            .card-padding {
              padding-right: .5rem !important;
              padding-left: .5rem !important;	
            }
          }
        `}
      </style>
    </>
  );
}

export default CurrencyInfiniteScroll;
