import React from 'react';
import { theme, websiteColors } from "./../themes/Theme";

const HeadingDescription = ({ category }) => {
    return (
        <div>
            <h2 className="category-heading">{category.title}</h2>
            {
                Array.isArray(category.description) ? category.description.map((description) => (
                    <p className="category-desc txt-color pb-3" dangerouslySetInnerHTML={{ __html: description }} />
                )) :
                    (
                        <p className="category-desc txt-color pb-3" dangerouslySetInnerHTML={{ __html: category.description }} />
                    )
            }
            <style jsx>
                {
                    `
                .category-heading {
                    font-weight: 600;
                    font-size: 30px;
                    line-height: 38px;
                    color: ${websiteColors[theme].headingColor};
                }
                .category-desc {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 28px;
                    color: ${websiteColors[theme].textColor};
                }
                `
                }
            </style>
        </div>

    )
}

export default HeadingDescription;