import React from 'react';
import { Icon } from '@iconify/react';
import CountryInfiniteScroll from '../pages/ConncetStripe/CountryInfiniteScroll';
import CurrencyInfiniteScroll from '../pages/ConncetStripe/CurrencyInfiniteScroll';
import PaymenMethod from '../pages/TapPayments/PaymentmethodScroll';
import { theme, websiteColors } from "./../themes/Theme";
import HeadingDescription from "./HeadingDescription";

const FeaturesCard = ({ featuresData, swapCard, displayScroll, stripePage }) => {
  // Reversing the description and image if swapCard is true
  return (
    <div className='px-4 pb-4'>
      <div className="px-lg-4 px-1 row py-lg-5 pb-3 d-flex flex-column gap-lg-4 gap-2 m-auto">
        {featuresData.map((category, index) => (
          <React.Fragment key={category.id}>
            <div className={`row mb-lg-5 mb-1 px-0 mx-0 category-card px-sm-3 px-0 align-items-center ${swapCard ? 'flex-row-reverse' : ''}`}>
              <div
                className={`col-lg-6 category-txt-container d-flex align-items-center justify-content-center order-1 order-lg-${(index % 2 === 0 ? "2" : "1")}`}
              >
                <div className="text-start icon-styles">
                  <HeadingDescription category={category} />
                  <div className="d-flex mt-3 gap-3">
                    {category.icons && category.icons.map((iconKey, idx) => (
                      <div key={idx} className='d-flex gap-4 rounded-circle'>
                        <Icon
                          icon={iconKey}
                          className="m-auto"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div
                className={`col-lg-6 img-h-container d-flex align-items-center justify-content-center order-2 order-lg-${index % 2 === 0 ? "1" : "2"}`}
              >
                <img
                  src={category.image}
                  alt={category.title}
                  className="img-fluid m-auto rounded"
                />
              </div>
            </div>

            {/* showing the currency, country and PaymenMethod scrollbar */}

            {displayScroll && index === 1 && (
              <div className="mt-4">
                {stripePage ? <CurrencyInfiniteScroll /> : <PaymenMethod />}
              </div>
            )}

            {/* Conditionally Render CountryInfiniteScroll After the 3rd Card */}
            {displayScroll && index === 2 && (
              <div className="mt-4">
                {stripePage ? <CountryInfiniteScroll /> : <CurrencyInfiniteScroll />
                }
              </div>
            )}
          </React.Fragment>
        ))}
      </div>

      <style jsx>{`
        .category-txt-container, .img-h-container {                                                                                        
          height: 100%;
        }

        .rounded-circle {
          width: 48px;
          height: 48px;
          background-color: ${websiteColors[theme].secondaryWebsiteTheme};
        }

        .img-fluid {
          max-width: 100%;
          max-height: 400px;
          object-fit: contain;
          width: auto;
          height: auto;
        }

        .icon-styles {
          color: ${websiteColors[theme].primaryWebsiteTheme} !important;
          font-size: 1.5rem !important;
          font-weight: 400;
        }

        @media screen and (max-width: 992px) {
          .category-txt-container {
            padding: 32px 16px;
          }

          .img-h-container {
            max-height: 400px;
            padding: 32px 16px;
          }

          .img-fluid {
            max-width: 400px;
          }
        }

        @media screen and (max-width: 576px) {
          .category-txt-container {
            padding: 32px 0px;
          }

          .img-fluid {
            max-width: 280px;
            max-height: 209px;
          }

          .category-subtitle {
            font-size: 14px !important;
          }

          .sub-title {
            font-size: 14px !important;
            white-space: nowrap;
          }
        }
      `}</style>
    </div>
  );
};

export default FeaturesCard;
